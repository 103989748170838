<template>
  <v-card elevation="12" min-height="600">
    <v-card-title>Amministrazione Ricognizione</v-card-title>
    <br />
    <v-container>
      <v-card-text> </v-card-text>
    </v-container>

    <v-row justify="center">
      <v-btn v-if="showStart" color="primary" @click="StartScanAsset">
        Inizio nuova ricognizione
      </v-btn>
      <v-btn v-else color="error" @click="dialog = true">
        Chiusura ricognizione in corso
      </v-btn>
      <v-snackbar
        v-model="permSnackbar"
        :color="snackbarColor"
        :timeout="timeout"
        :multi-line="true"
      >
        {{ errorText }}
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
    <v-row justify="center">
      <v-dialog  class="ma-2" v-model="dialog" persistent max-width="400">
        <v-card >
          <v-card-text class="text-h5 pa-2">
            Sei sicuro di voler terminare la ricognizione e generare i report?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="ma-2" text @click="dialog = false">
              Annulla
            </v-btn>
            <v-btn color="primary" class="ma-2" text @click="FinishScanAsset">
              CONFERMA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
// @ is an alias to /src

// Desired features
// - generazione report complessivo, per persona e per stanza beni rinvenuti e non (resi disponibili in una zona storico)
//

// Tabelle

// Reports -> id, nome file, id ricognizione
// Ricognizioni -> id, inizio, fine, stato corrente,

// Tabelle non nostre -> aggiornamento campo bene ultimo rilevamento
//                    -> (primo giro) associazione id tag rfid o qrcode al bene

// Funzionalità da garantire
//                    -> opzionale nuovo luogo bene

export default {
  name: "ConfRicognizione",

  data: () => ({
    scanAssetState: null,
    showStart: true,

    //// SNACKBAR DATA
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    timeout: -1,
    //////////////////
    dialog: false,
  }),

  methods: {
    StartScanAsset() {
      console.log("start new scan asset process");

      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API + "/scanAssets/startScanAsset",
          null,
          { headers }
        )
        .then((response) => {
          console.log(response.data.scanAsset);
          if (response.data.scanAsset.STATE == "running") {
            this.permSnackbar = true;
            this.snackbarColor = "success";
            this.errorText =
              "Iniziata nuova ricognizione in data: " +
              response.data.scanAsset.STARTDATE;
            this.timeout = -1;
            // If scanasset is running then hide the start button
            this.showStart = false;
          } else {
            console.log(
              "No recognition running found, display the start new button!!!"
            );
            this.showStart = true;
          }
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
          this.permSnackbar = true;
          this.snackbarColor = "error";
          this.errorText = error.response.data;
          this.timeout = -1;

          this.FetchScanAssetState();
        });
    },

    FinishScanAsset() {
      console.log("stop an existing scan asset process");

      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API + "/scanAssets/finishScanAsset",
          null,
          { headers }
        )
        .then((response) => {
          console.log(response.data.scanAsset);
          if (response.status == 200) {
            this.permSnackbar = true;
            this.snackbarColor = "success";
            this.errorText =
              "Ricognizione conclusa e report generati in data: " +
              response.data.scanAsset.ENDDATE;
            this.timeout = -1;
            // If scanasset is running then hide the start button
            this.showStart = true;
            this.dialog = false;
          }
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
          this.permSnackbar = true;
          this.snackbarColor = "error";
          this.errorText = error.response.data;
          this.timeout = -1;

          this.FetchScanAssetState();
        });
    },

    FetchScanAssetState() {
      // Get the current state if any, from backend
      console.log("retrieving scan asset state...");

      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/scanAssets/state", {
          headers,
        })
        .then((response) => {
          console.log(response);
          if (
            response.data.found &&
            (response.data.state == "running" ||
              response.data.state == "completed")
          ) {
            // If scanasset is running then hide the start button
            this.showStart = false;
          } else {
            console.log(response.data.found + " - " + response.data.state);
            console.log(
              "No recognition running found, display the start new button!!!"
            );
            this.showStart = true;
          }
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
          // this.permSnackbar = true;
          // this.snackbarColor = "error";
          // this.errorText = error.response.data;
          // this.timeout= -1;
        });
    },
  },

  created: function () {
    // At creation check the surrent state on the backend db
    this.FetchScanAssetState();
  },

  beforeDestroy: function () {},
};
</script>